import {publicIp} from 'public-ip';

let BACKEND_URL=""
let isDev=false;
let devPort=0;
if(process.env.REACT_APP_ENVIRONMENT==="development")
{
  isDev=true;
  devPort=4000;
}
else if(process.env.REACT_APP_ENVIRONMENT==="build-dev")
{
  isDev=true;
}

// DEV
if(isDev)
{
  if(devPort===4000)
    BACKEND_URL = "http://localhost:"+devPort;
  else
    BACKEND_URL = "http://dev.articleai.io";
}
// PROD
else
  BACKEND_URL = "https://www.articleai.io";

export async function AiSiteGetConfig()
{
  const response= await fetch(BACKEND_URL+'/site/config?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiStripeGetConfig()
{
  const response= await fetch(BACKEND_URL+'/transaction/stripe/config?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiSiteGetArticles(page)
{
  const response= await fetch(BACKEND_URL+'/site/blog/'+page+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiSiteGetArticle(id)
{
  const response= await fetch(BACKEND_URL+'/site/blog-detail/'+id+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiValidatePromoCode(code,amount)
{
  const response= await fetch(BACKEND_URL+'/site/promocode/'+code+'/'+amount+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiStripeCreateCustomer()
{
  const response= await fetch(BACKEND_URL+'/transaction/stripe/create-customer',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiStripeCreateSubscription(transactionId,hash,priceId,code="")
{
  const JSONData={
    "transactionId":transactionId,
    "hash":hash,
    "priceId":priceId,
    "code":code
  };
  const response= await fetch(BACKEND_URL+'/transaction/stripe/create-subscription',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false,error:await response.text()};
};

export async function AiStripeCancelSubscription()
{
  const response= await fetch(BACKEND_URL+'/transaction/stripe/cancel-subscription',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
  {
    const parsed = await response.json();
    return {"status":true,"subscription":parsed.subscription};
  }
  return {"status":false};
};

export async function AiCryptoCancelSubscription()
{
  const response= await fetch(BACKEND_URL+'/transaction/crypto/cancel-subscription',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return {"status":true};
  return {"status":false};
};

export async function AiStripeCreatePayment(transactionId,hash,price)
{
  const JSONData={
    "transactionId":transactionId,
    "hash":hash,
    "price":price
  };
  const response= await fetch(BACKEND_URL+'/transaction/stripe/create-payment',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiUserCreate(walletAddress,firstName,lastName,phone,email,pwd,token,byPass=false)
{
  let userIp="";
  try {
    userIp=await publicIp();
  } catch (e) {
    userIp="127.0.0.1";
    console.info("ARTICLE AI WARNING : "+e.message);
  } finally {
  }
  const JSONData={
    "ip":userIp,
    "walletAddress":walletAddress,
    "firstName":firstName,
    "lastName":lastName,
    "phone":phone,
    "email":email,
    "pwd":pwd,
    "token":token,
    "byPass":byPass
  };
  const response= await fetch(BACKEND_URL+'/user',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiUserUpdate(walletAddress,firstName,lastName,phone,email,pwd,openaiKey)
{
  /*if(typeof userId === "undefined" || userId<=0 || userId==="")
  {
    console.error("ARTICLE AI ERROR : UserId is required");
    return {"status":false};
  }*/
  const JSONData={};
  if(typeof walletAddress !== "undefined") JSONData.walletAddress=walletAddress;
  if(typeof firstName !== "undefined") JSONData.firstName=firstName;
  if(typeof lastName !== "undefined") JSONData.lastName=lastName;
  if(typeof phone !== "undefined") JSONData.phone=phone;
  if(typeof email !== "undefined") JSONData.email=email;
  if(typeof pwd !== "undefined") JSONData.pwd=pwd;
  if(typeof openaiKey !== "undefined") JSONData.openaiKey=openaiKey;

  const response= await fetch(BACKEND_URL+'/user',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'//,
      //'userId': userId
    },
    method:"PUT",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiUserForgotPwd(email)
{
  const JSONData={
    "email":email
  };
  const response= await fetch(BACKEND_URL+'/user/forgot-pwd',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiUserValidateEmail(userId,pin)
{
  const response= await fetch(BACKEND_URL+'/user/validate/'+userId+'/'+pin+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiUserValidatePwd(userId,pin)
{
  const response= await fetch(BACKEND_URL+'/user/reset-pwd/'+userId+'/'+pin+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiUserChangePwd(userId,pin,pwd)
{
  const JSONData={
    "userId":userId,
    "pin":pin,
    "pwd":pwd
  };
  const response= await fetch(BACKEND_URL+'/user/change-pwd',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiUserLoginWithWalletAddress(walletAddress)
{
  if(typeof walletAddress === "undefined" || walletAddress<=0 || walletAddress==="")
  {
    console.error("ARTICLE AI ERROR : walletAddress is required");
    return {"status":false};
  }
  const JSONData={
    "walletAddress":walletAddress,
    "email":"",
    "pwd":""
  };
  const response= await fetch(BACKEND_URL+'/user/login',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}

export async function AiUserLoginWithEmailPwd(email,pwd,byPass=false)
{
  if(typeof email === "undefined" || email==="" || typeof pwd === "undefined" || pwd==="")
  {
    console.error("ARTICLE AI ERROR : email and pwd are required");
    return {"status":false};
  }
  const JSONData={
    "walletAddress":"",
    "email":email,
    "pwd":pwd,
    "byPass":byPass
  };
  const response= await fetch(BACKEND_URL+'/user/login',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}

export async function AiUserRelogin()
{
  const response= await fetch(BACKEND_URL+'/user/relogin?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}

export async function AiUserLogout()
{
  const response= await fetch(BACKEND_URL+'/user/logout?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}

export async function AiUserCloseAccount()
{
  const response= await fetch(BACKEND_URL+'/user/close',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return {"status":true};
  return {"status":false};
};

export async function AiUserProfile()
{
  /*if(typeof userId === "undefined" || userId<=0 || userId==="")
  {
    console.error("ARTICLE AI ERROR : UserId is required");
    return {"status":false};
  }*/
  const response= await fetch(BACKEND_URL+'/user?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
//      "userId": userId
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}

export async function AiGetSubscriptions()
{
  /*if(typeof userId === "undefined" || userId<=0 || userId==="")
  {
    console.error("ARTICLE AI ERROR : UserId is required");
    return {"status":false};
  }*/
  const response= await fetch(BACKEND_URL+'/user/subscriptions?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiTransactionCreate(txId,amount,method,articlesQty,usdAmount)
{
  /*if(typeof userId === "undefined" || userId<=0 || userId==="")
  {
    console.error("ARTICLE AI ERROR : UserId is required");
    return {"status":false};
  }*/
  const JSONData={
    "txId":txId,
    "amount":amount,
    "method":method,
    "articlesQty":articlesQty,
    "usdAmount":usdAmount
  };
  const response= await fetch(BACKEND_URL+'/transaction',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //'userId': userId
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiTransactionValidateCrypto(networkName,hash,amount)
{
  if(typeof networkName ==="undefined" || networkName==="")
  {
    console.error("ARTICLE AI ERROR : networkName is required");
    return {"status":false};
  }
  const JSONData={
    "hash":hash,
    "amount":amount
  };
  const response= await fetch(BACKEND_URL+'/transaction/validate-crypto',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //'userId': userId,
      'Network-Name':networkName
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiIdeaStatus()
{
  const response= await fetch(BACKEND_URL+'/article/idea/status?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiIdeaList()
{
  const response= await fetch(BACKEND_URL+'/article/idea/list?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiArticleList(ideaId)
{
  const response= await fetch(BACKEND_URL+'/article/list/'+ideaId+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

/*export async function AiArticleQuote(articlesQty)
{
  const response= await fetch(BACKEND_URL+'/article/quote',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //'userId': userId,
      'articlesQty': articlesQty
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};*/

export async function AiArticleIdeaDelete(ideaId)
{
  const JSONData={
    "ideaId":ideaId
  };
  const response= await fetch(BACKEND_URL+'/article/idea',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    method:"DELETE",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiArticleDelete(articleId)
{
  const JSONData={
    "articleId":articleId
  };
  const response= await fetch(BACKEND_URL+'/article',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    method:"DELETE",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiArticleStatus(ideaId)
{
  let extra="";
  if(typeof ideaId!=="undefined")
    extra=ideaId;
  const response= await fetch(BACKEND_URL+'/article/status/'+extra+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false,"error":"not logged"};
};

export async function AiGenerateIdeas(phrase,articlesQty,keywordsQty)
{
  /*if(typeof userId === "undefined" || userId<=0 || userId==="")
  {
    console.error("ARTICLE AI ERROR : UserId is required");
    return {"status":false};
  }*/
  const JSONData={
    "phrase":phrase,
    "articlesQty":articlesQty,
    "keywordsQty":keywordsQty
  };
  const response= await fetch(BACKEND_URL+'/article/generate-ideas',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiCheckIdeas(ideaId)
{
  const response= await fetch(BACKEND_URL+'/article/generate-ideas/'+ideaId+"?t="+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiGenerateArticles(ideas)
{
  /*if(typeof userId === "undefined" || userId<=0 || userId==="")
  {
    console.error("ARTICLE AI ERROR : UserId is required");
    return {"status":false};
  }*/
  const JSONData={
    "ideas":ideas
  };
  const response= await fetch(BACKEND_URL+'/article/generate-articles',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiCheckArticles(ideaId)
{
  const response= await fetch(BACKEND_URL+'/article/generate-articles/'+ideaId+"?t="+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiTransactionList()
{
  const response= await fetch(BACKEND_URL+'/transaction/list?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiSubscriptionList()
{
  const response= await fetch(BACKEND_URL+'/user/subscription/list?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiCreditList()
{
  const response= await fetch(BACKEND_URL+'/user/credit/list?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiCredits()
{
  const response= await fetch(BACKEND_URL+'/user/credits?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};





// QUEUE START
export async function AiQueueCreate(value)
{
  const JSONData={
    "value":value,
  };
  const response= await fetch(BACKEND_URL+'/user/queue',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiQueueRemove(value)
{
  const JSONData={
    "value":value,
  };
  const response= await fetch(BACKEND_URL+'/user/queue',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"DELETE",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiQueueList()
{
  const response= await fetch(BACKEND_URL+'/user/queue?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};
// QUEUE END



// INSPIRATION START
export async function AiInspirationCreate(id,title,content)
{
  const JSONData={
    id:id,
    title:title,
    content:content
  };
  const response= await fetch(BACKEND_URL+'/user/inspiration',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiInspirationRemove(id)
{
  const JSONData={
    id:id
  };
  const response= await fetch(BACKEND_URL+'/user/inspiration',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"DELETE",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiInspirationList(id)
{
  let extra="/0";
  if(typeof id !== "undefined")
    extra="/"+id;
  const response= await fetch(BACKEND_URL+'/user/inspiration'+extra+'?t='+(new Date().getTime()),
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};
// INSPIRATION END



export async function AiScrapeUrl(url)
{
  const JSONData = {
    url: url
  };
  const response= await fetch(BACKEND_URL+'/article/scrape/',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.text();
  return "";
};












// NEW
export async function AiAdvancedGet(id)
{
  const response = await fetch(BACKEND_URL + '/advanced/'+id+"?t="+(new Date().getTime()), {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method: "GET"
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"error":"nothing"};
};

export async function AiAdvanced(type,title,words,inspiredBy,inspiredByContent,description)
{
  const JSONData = {
    "type": type,
    "title": title,
    "words": words,
    "inspiredBy": inspiredBy,
    "inspiredByContent": inspiredByContent,
    "prompt": description
  };

  const response = await fetch(BACKEND_URL + '/advanced', {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method: "POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"error":"nothing"};
};

export async function AiAdvancedSave(title,article,queue)
{
  const JSONData={
    "title":title,
    "article":article,
    "queue":queue
  };
  const response= await fetch(BACKEND_URL+'/advanced/save',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiAdvancedUpdateQueue(id,queue)
{
  const JSONData={
    "id":id,
    "queue":queue
  };
  const response= await fetch(BACKEND_URL+'/advanced/update',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};

export async function AiQuoteIdea(phrase,articlesQty,keywordsQty)
{
  const JSONData={
    "phrase":phrase,
    "articlesQty":articlesQty,
    "keywordsQty":keywordsQty
  };
  const response= await fetch(BACKEND_URL+'/article/quote-ideas',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}

export async function AiQuoteArticle(ideas)
{
  const JSONData={
    "ideas":ideas
  };
  const response= await fetch(BACKEND_URL+'/article/quote-articles',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}

export async function AiQuoteAdvanced(words,inspiredByContent,prompt)
{
  const JSONData={
    "words":words,
    "inspiredByContent":inspiredByContent,
    "prompt":prompt
  };
  const response= await fetch(BACKEND_URL+'/advanced/quote',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
}





// TO REMOVE
/*export async function AiCreateProject(projectName,projectType,prompt)
{
  const JSONData={
    "projectName":projectName,
    "projectType":projectType,
    "prompt":prompt
  };
  const response= await fetch(BACKEND_URL+'/project/',
  {
    credentials: 'include',
		headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      //"userId": userId
    },
    method:"POST",
    body: JSON.stringify(JSONData)
  }).catch(error=>{
    console.error("ARTICLE AI ERROR : "+error);
  }).finally(()=>{
  });
  const { status } = response;
  if(status===200)
    return await response.json();
  return {"status":false};
};*/
