import {React,useState,useEffect} from "react";
import AppRoutes from "./routes/Routes";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import LoadingPage from "./pages/LoadingPage";
import { GoogleOAuthProvider } from '@react-oauth/google';
import TagManager from "react-gtm-module";

import UserContext from './UserContext';

// Web3
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { /*mainnet, goerli,*/ bsc/*, bscTestnet*/ } from 'wagmi/chains';

// API
import {AiUserProfile,AiUserUpdate,AiSiteGetConfig,AiUserRelogin} from "./components/articleai/Api.js";


const App = () => {
  const navigate = useNavigate();

  // GET SITE CONFIG
  const [userData, setUserData] = useState(false);

  useEffect(()=>{
    async function fetchData()
    {
      const batchSettings=[];
      batchSettings.push(AiSiteGetConfig());
      batchSettings.push(AiUserRelogin());
      const loadedSettings=await Promise.all(batchSettings)
      const siteConfig=loadedSettings[0];
      const loginSession=loadedSettings[1];

      let isLogged=false;
      let userId=0;
      let isSubscribed=false;
      let subscriptionType=0;
      let confirmed=false;

      if(typeof loginSession.status !== "undefined")
        isLogged=loginSession.status;
      if(typeof loginSession.userId !== "undefined")
        userId=loginSession.userId;
      if(typeof loginSession.isSubscribed !== "undefined")
        isSubscribed=loginSession.isSubscribed;
      if(typeof loginSession.subscriptionType !== "undefined")
        subscriptionType=loginSession.subscriptionType;
      if(typeof loginSession.confirmed !== "undefined")
        confirmed=loginSession.confirmed;

      const userObject={
        config: siteConfig,
        isLogged:isLogged,
        userId:userId,
        isSubscribed:isSubscribed,
        subscriptionType:subscriptionType,
        confirmed:confirmed,
        processing:[]
      };
      setUserData(userObject);

      const script = document.createElement("script");
      script.src = "https://www.googletagmanager.com/gtag/js?id="+siteConfig.google_analytics;
      script.async = true;
      script.crossOrigin = "anonymous";
      document.body.appendChild(script);

      if (typeof window == 'undefined') {
        return;
      }
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }

      //const env = process.env.REACT_APP_ENVIRONMENT; 
      const tagManagerArgs = {
        gtmId: siteConfig.googletag_id,
        //the below changes GTM values based on whether dev or production
        //auth: env === "development" || env === "build-dev"
         //   ? ""
        //    : siteConfig.google_analytics,
        //preview: env === "development" || env === "build-dev"
        //  ? ""
        //  : siteConfig.google_analytics
      }; 
      TagManager.initialize(tagManagerArgs)


      if(!isLogged)
      {
        //console.log(window.location.pathname);
        if(
          window.location.pathname==="/create" ||
          window.location.pathname==="/profile" ||
          window.location.pathname==="/documents" ||
          window.location.pathname==="/transactions" ||
          window.location.pathname==="/subscriptions" ||
          window.location.pathname==="/credits" ||
          window.location.pathname==="/api"
        )
          navigate("/login");
      }

      gtag('js', new Date());
      gtag('config', siteConfig.google_analytics);
      gtag('config', siteConfig.google_adwords);

      return () => {
        document.body.removeChild(script)
      }

    }
    fetchData();
    // eslint-disable-next-line
  },[]);

  // SET WEB3 CONNECTION
  const chains = [ /*mainnet, goerli,*/ bsc/*, bscTestnet*/ ];
  const projectId = '5d69eaddad58ed3e4d058f9984d66401';
  const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, version: 2, chains }),
    publicClient/*,
    UNSTABLE_shimOnConnectSelectAccount:true,
    shimdisconnect:true*/
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);
  
  const ResendEmail = async () => {
    const data=await AiUserProfile();
    if(data.status && data.data.email!=="")
    {
      const result=await AiUserUpdate("",data.data.firstName,data.data.lastName,data.data.phone,data.data.email,"",data.data.openaiKey);
      if(result.status)
      {
        alert("Email sent. Check your email inbox.");
      }
      else
      {
        alert("Wait 30 minutes before retry.")
      }
    }
    return false;
  }

  function RootTag({confirmed})
  {
    if(userData.isLogged && !confirmed)
      return <div><div style={{backgroundColor:"lightyellow",textColor:"black",padding:"5px",right:"0",bottom:"0","position":"fixed","textAlign":"right","zIndex":999,"border":"1px solid #ccc"}}>Confirm your email address. Click <Link onClick={ResendEmail}>here</Link> to resend</div><AppRoutes /></div>
    else
      return <AppRoutes />
  }

  if(!userData)
    return (<><LoadingPage /></>);
  else {
    return (
      <UserContext.Provider value={{ userData, setUserData }}>
        <WagmiConfig config={wagmiConfig}>
          <GoogleOAuthProvider clientId="505589944365-a1moj3216vitcjmnfpbjmuetmfudcpnt.apps.googleusercontent.com">
            <RootTag confirmed={userData.confirmed} />
          </GoogleOAuthProvider>
        </WagmiConfig>
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          themeVariables={{
            '--w3m-z-index': '9999'
          }}
          defaultChain={bsc}
        />
      </UserContext.Provider>
    );
  }
};

export default App;
