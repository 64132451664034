import React from "react";
import Layout from "../components/layout/Layout";

export default function LoadingPage() {
  return (<>
    <Layout>
      <section
        className="hero-section ptb-100 background-img full-screen"
      ></section>
    </Layout>
  </>);
}
