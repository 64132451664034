//import {publicIp} from 'public-ip';

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
// Owl Carousel
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//React Modal
import "react-modal-video/scss/modal-video.scss";

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

/*let allow=true;
let userIp="";
try {
  userIp=await publicIp();
  let country_name = localStorage.getItem(userIp);
  if(!country_name)
  {
    const response= await fetch("https://ip-api.io/json/" + userIp)
    .catch(error=>{
      console.error("ARTICLE AI ERROR : "+error);
    }).finally(()=>{
    });
    if(typeof response !== "undefined")
    {
      const { status } = response;
      if(status===200)
      {
        const myJson=await response.json();
        localStorage.setItem(userIp, myJson.country_name);
      }
    }
  }

  //console.log(myJson.country_name);
  if(typeof country_name !== "undefined" &&
    (country_name==="India" ||
    country_name==="Pakistan" ||
    country_name==="Israel" ||
    country_name==="Palestine" ||
    country_name==="Saudi Arabia" ||
    country_name==="China" ||
    country_name==="Russia")
  )
  {
    allow=false;
  }

} catch (e) {
  userIp="127.0.0.1";
  //console.info("ARTICLE AI WARNING : "+e.message);
} finally {
}

if(allow)
{*/

  Sentry.init({
    dsn: 'https://a6ecd3910ff1498fae41e61973e4178b@o4505150441455616.ingest.sentry.io/4505150477565952', // Replace with your Sentry DSN
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });

  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
/*}
else
{
  window.location.href="https://www.google.com";
}*/
