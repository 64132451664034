import { createContext } from 'react';

const UserContext = createContext({
  userData: {
    config:{
      walletAddress:"",
      priceUsd:0,
      priceWei:0,
      inspiredByOptions:[],
      recaptcha:"",
      google_analytics:"",
      google_adwords:"",
      google_conversion:""
    },
    isLogged:false,
    userId:0,
    isSubscribed:false,
    confirmed:false
  },
  setUserData: () => {},
});

export default UserContext;
