import React, { Suspense, lazy } from 'react';
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import LoadingPage from "../pages/LoadingPage";

const HomeTwo = lazy(() => import("../themes/index-2/HomeTwo"));
const SignupPage = lazy(() => import("../pages/SignupPage"));
const Login = lazy(() => import("../pages/Login"));
const ArticleAiStart = lazy(() => import("../components/articleai/Start"));
const ArticleAiCreate = lazy(() => import("../components/articleai/Create"));
const ArticleAiCreate2 = lazy(() => import("../components/articleai/Create2"));
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const DocumentsPage = lazy(() => import("../pages/DocumentsPage"));
const TransactionsPage = lazy(() => import("../pages/TransactionsPage"));
const SubscriptionsPage = lazy(() => import("../pages/SubscriptionsPage"));
const CreditsPage = lazy(() => import("../pages/CreditsPage"));
const ApiPage = lazy(() => import("../pages/ApiPage"));
const BlogWithSidebar = lazy(() => import("../pages/BlogWithSidebar"));
const BlogDetails = lazy(() => import("../pages/BlogDetails"));
const HowTo = lazy(() => import("../pages/HowTo"));
const WpPlugin = lazy(() => import("../pages/WpPlugin"));
const About = lazy(() => import("../pages/About"));
const Terms = lazy(() => import("../pages/Terms"));
const Privacy = lazy(() => import("../pages/Privacy"));
const ChangePassword = lazy(() => import("../components/others/ChangePassword"));
const PasswordReset = lazy(() => import("../components/others/PasswordReset"));
const EmailUpdate = lazy(() => import("../components/others/EmailUpdate"));
const ComingSoon = lazy(() => import("../components/others/ComingSoon"));
const NotFound = lazy(() => import("../components/others/NotFound"));

const AppRoutes = () => {
  return (
    <>
      <ScrollToTop />
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path="/" element={<HomeTwo />} />
          <Route path="/how-to" element={<HowTo />} />
          <Route path="/wp-plugin" element={<WpPlugin />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/start" element={<ArticleAiStart />} />
          <Route path="/create" element={<ArticleAiCreate />} />
          <Route path="/create2" element={<ArticleAiCreate2 />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/documents" element={<DocumentsPage />} />
          <Route path="/transactions" element={<TransactionsPage />} />
          <Route path="/subscriptions" element={<SubscriptionsPage />} />
          <Route path="/credits" element={<CreditsPage />} />
          <Route path="/api" element={<ApiPage />} />
          <Route path="/articles/:page?" element={<BlogWithSidebar />} />
          <Route path="/blog/:id/:slug" element={<BlogDetails />} />
          <Route path="/password-change" element={<ChangePassword />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/email-update" element={<EmailUpdate />} />
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
